import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertToastrService {

  private toastr = inject(ToastrService);
  private translate = inject(TranslateService);

  showSuccess(messageKey: string, params?: any) {
    this.translate.get(messageKey, params).subscribe(string => {
      this.toastr.success(string, '', {
        tapToDismiss: true,
        enableHtml: true,
        closeButton: true,
        timeOut: 5000,
        easeTime: 300,
        positionClass: 'toast-top-right',
      });
    });
  }

  showError(messageKey: string, params?: any) {
    this.translate.get(messageKey, params).subscribe(string => {
      this.toastr.error(string, '', {
        tapToDismiss: true,
        enableHtml: true,
        closeButton: true,
        timeOut: 5000,
        easeTime: 300,
        positionClass: 'toast-top-right',
      });
    });
  }

  showErrorMessageWithoutTranslation(message: string) {
    this.toastr.error(message, '', {
      tapToDismiss: true,
      enableHtml: true,
      closeButton: true,
      timeOut: 5000,
      easeTime: 300,
      positionClass: 'toast-top-right',
    });
  }

  showSuccessMessageWithoutTranslation(message: string) {
    this.toastr.success(message, '', {
      tapToDismiss: true,
      enableHtml: true,
      closeButton: true,
      timeOut: 5000,
      easeTime: 300,
      positionClass: 'toast-top-right',
    });
  }
}

import { inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE_CONSTANT } from '@constants/localstorage.constant';
import { AlertToastrService } from '@util-services/alert-toastr.service';
import { CryptoService } from '@util-services/crypto.service';
import { LocalStorageService } from '@util-services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  protected featuresList: { [key: string]: boolean | number } = {};
  protected masterFeaturesList: { requiredSubscriptionPlan: number }[] = [];

  private alertToastrService = inject(AlertToastrService);
  private localStorageService = inject(LocalStorageService);
  private cryptoService = inject(CryptoService);

  setUserPermissions(permissions: string[]): void {
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.USER_PERMISSIONS, this.cryptoService.encryptValue(JSON.stringify(permissions)));
  }

  getUserPermissions(): string[] {
    const encryptedString: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.USER_PERMISSIONS);
    if (encryptedString) {
      const decryptedString: string = this.cryptoService.decryptValue(encryptedString);
      return JSON.parse(decryptedString);
    } else {
      return [];
    }
  }

  /**
   * Checks if the user has the specified permission(s).
   *
   * @param value - A single permission or an array of permissions to check.
   * @param filterModeAND - If true, all permissions in the array must be present (AND behavior).
   *                        If false, at least one permission in the array must be present (OR behavior).
   *                        Defaults to false.
   * @returns `true` if the user has the required permission(s), otherwise `false`.
   */
  hasPermission(value: string | string[], filterModeAND = false): boolean {
    if (!value) {
      return true;
    }

    if (typeof value === 'string') {
      value = [value.toString()];
    }

    const permissions = this.getUserPermissions();
    const userPermissions = value as string[];

    if (filterModeAND) {
      // AND behavior: All permissions must be present
      return userPermissions.every(permission => permissions.includes(permission));
    } else {
      // OR behavior: At least one permission must be present
      return userPermissions.some(permission => permissions.includes(permission));
    }
  }

  permissionNotAvailable(): void {
    this.alertToastrService.showError('general.403_error_message');
  }
}

import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { SAFERPAY_AUTHORIZED_STATUS, SAFERPAY_CAPTURED_STATUS, SAFERPAY_ERROR_STATUS, SAFERPAY_ERROR_STATUS_MESSAGES } from '@constants/saferpay-status.constants';
import { TranslateModule } from '@ngx-translate/core';
import { LoggerService } from '@util-services/logger.service';
import { SaferpayService } from '@util-services/saferpay.service';
import { takeWhile, timer } from 'rxjs';

@Component({
  selector: 'app-saferpay-payment-verification',
  templateUrl: './saferpay-payment-verification.component.html',
  styleUrl: './saferpay-payment-verification.component.scss',
  standalone: true,
  imports: [TranslateModule]
})
export class SaferpayPaymentVerificationComponent {

  private destroyRef = inject(DestroyRef);
  private route = inject(ActivatedRoute);
  private saferpayService = inject(SaferpayService);

  protected paymentSuccess: boolean;
  protected paymentFailedMessage: string;
  protected showPaymentVerificationLoader: boolean;
  protected enablePolling = false;
  protected paymentNotFound = false;

  constructor() {
    this.route.queryParams.subscribe(params => {
      params['order-uuid'] && this.getOrderPaymentStatus(params['order-uuid']);
    });
  }

  private getOrderPaymentStatus(paymentUuid: string): void {
    this.showPaymentVerificationLoader = true;
    this.saferpayService.getOrderPaymentStatus(paymentUuid)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: data => {
          if (data?.status != null) {
            this.enablePolling = false;
            this.showPaymentVerificationLoader = false;
            const finalStatus = data.status.toLowerCase();
            if ([SAFERPAY_AUTHORIZED_STATUS, SAFERPAY_CAPTURED_STATUS].includes(finalStatus)) {
              this.paymentSuccess = true;
            } else if (SAFERPAY_ERROR_STATUS.includes(finalStatus)) {
              this.paymentFailedMessage = SAFERPAY_ERROR_STATUS_MESSAGES[finalStatus] || '';
              this.paymentSuccess = false;
            } else {
              this.paymentSuccess = false;
            }
          } else {
            this.enablePolling = true;
            this.paymentSuccess = false;
            this.showPaymentVerificationLoader = true;
            this.pollingInitialize(paymentUuid);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.showPaymentVerificationLoader = false;
          if (error.status === HttpStatusCode.NotFound) {
            this.paymentNotFound = true;
          }
          this.paymentSuccess = false;
          LoggerService.error(error);
        }
      });
  }

  private pollingInitialize(paymentUuid: string): void {
    timer(5 * 1000)
      .pipe(
        takeWhile(() => this.enablePolling),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.getOrderPaymentStatus(paymentUuid));
  }
}

import { BookingTypesDbModel } from '@db-models/booking-types.model';
import { ConferenceStatusType } from '@db-models/conference-db.model';
import { WorkerAttributeDbModel } from '@db-models/worker-db.model';

export class EmployeeFormModel {
  uuid: string;
  id: number;
  prename: string;
  lastname: string;
  username: string;
  email: string;
  password: string;
  password_repeat: string;
  job_title: string;
  conference_url: string;
  store_id: number;
  bookable: boolean | number;
  is_directly_bookable: boolean;
  show_in_pro_calendar: boolean | number;
  is_reseller_admin: boolean | number;
  is_round_robin_enabled: boolean | number;
  appointmentServices?: number[];
  is_resource: boolean | number;
  bookingType: BookingTypesDbModel;
  booking_label: string;
  description: string;
  booking_type_id: number;
  booking_type_uuid: string;
  booking_type: BookingTypesDbModel;
  lang_identifier: string;
  mobile: string;
  calendar_color: string;
  role_ids: number[];
  is_multi_language: number;
  is_location_dependent: boolean | string;
  hide_past_appointments: number;
  block_resource_dependencies: string;
  show_availabilities_in_calendar: number;
  appointment_booking_limit: number;
  _translations: {
    [key: string]: EmployeeMultiTranslationForm
  };
  formControl_default: EmployeeMultiTranslationForm;
  conference_filter_state?: ConferenceStatusType;
  attributes: { [key: string]: boolean | string } | WorkerAttributeDbModel[];
}

export class ServiceWorkerModel {
  worker_id: number;
  service_ids: number[];
}

export class EmployeeMultiTranslationForm {
  job_title?: string;
  booking_label?: string;
  description?: string;
  full_name?: string;
  resource_name?: string;
}
